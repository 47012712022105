// Import Bootstrap
import 'bootstrap';

import domReady from '@roots/sage/client/dom-ready';

/**
 * Application entrypoint
 */
domReady(async () => {
  // ...
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);

import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faPhoneFlip, faEnvelope } from '@fortawesome/free-solid-svg-icons';
library.add(faLinkedin, faPhoneFlip, faEnvelope);
dom.watch();